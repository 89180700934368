import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { FiAlertCircle } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import { ISector } from "../../../@types/types";
import Loading from "../../../components/Loading";
import api from "../../../service/api";
import theme from "../../../styles/theme";
import {
  ButtunSubmit,
  Container,
  ContainerForm,
  Error,
  Form,
  ScreenLoading,
} from "../styles";

interface Props {
  location: {
    state: {};
  };

  match: {
    params: {
      id: number;
    };
  };
}

const Detail: React.FC<Props> = ({ match }) => {
  const { addToast } = useToasts();
  const history = useHistory();

  const [contentPage, setContentPage] = useState<ISector>();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (match.params.id) {
      (async () => {
        const dataDB = await api.get<any>(`/sector/${match.params.id}`);

        setContentPage(dataDB.data);
        setLoading(false);
      })();
    }
  }, [match.params.id]);

  useEffect(() => {
    if (contentPage) {
      if (contentPage.id) formik.setFieldValue("id", contentPage.id);
      if (contentPage.name) formik.setFieldValue("name", contentPage.name);
      if (contentPage.abbreviation)
        formik.setFieldValue("abbreviation", contentPage.abbreviation);

      if (contentPage.is_active === true || contentPage.is_active === false)
        formik.setFieldValue("is_active", contentPage.is_active ? 1 : 0);
    }
    // eslint-disable-next-line
  }, [contentPage]);

  async function handleSubmit(updates: any) {
    try {
      await api.put(`/sector/${updates.id}`, {
        ...updates,
        is_active: Number(updates.is_active) === 1 ? true : false,
      });
      addToast("Setor alterado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/setores");
    } catch (err) {
      const error = err as any;

      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  const formik = useFormik({
    initialValues: {
      id: undefined,
      name: undefined,
      abbreviation: undefined,
      is_active: undefined,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Nome é obrigatório"),
      abbreviation: Yup.string().required("Abreviação é obrigatório"),
    }),
    onSubmit: (data) => handleSubmit(data),
  });

  return (
    <Container>
      {!loading ? (
        <>
          <header>
            <button
              type="button"
              onClick={() => {
                history.goBack();
              }}
              className="arrowBack"
            >
              <FaArrowLeft size={18} />
            </button>
            <h2>Setor</h2>
          </header>

          <ContainerForm>
            <Form onSubmit={formik.handleSubmit}>
              <div className="field">
                <label htmlFor="id">ID</label>
                <input
                  type="text"
                  style={{ borderStyle: "dashed" }}
                  className="id"
                  {...formik.getFieldProps("id")}
                  readOnly={true}
                />
              </div>
              <div className="field-group">
                <div className="field">
                  <label htmlFor="name">Nome</label>
                  <input id="name" {...formik.getFieldProps("name")} />
                  {formik.touched.name && formik.errors.name ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.name} </span>
                    </Error>
                  ) : null}
                </div>
                <div className="field">
                  <label htmlFor="abbreviation">Abreviação</label>
                  <input
                    id="abbreviation"
                    {...formik.getFieldProps("abbreviation")}
                  />
                  {formik.touched.abbreviation && formik.errors.abbreviation ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.abbreviation} </span>
                    </Error>
                  ) : null}
                </div>
              </div>

              <div className="field">
                <label htmlFor="is_active">Ativo</label>
                <select id="is_active" {...formik.getFieldProps("is_active")}>
                  <option value={0}>Não</option>
                  <option value={1}>Sim</option>
                </select>

                {formik.touched.is_active && formik.errors.is_active ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.is_active} </span>
                  </Error>
                ) : null}
              </div>

              <ButtunSubmit type="submit"> Alterar dados </ButtunSubmit>
            </Form>
          </ContainerForm>
        </>
      ) : (
        <ScreenLoading>
          <Loading
            colorLoading={theme.colors.primary}
            size={40}
            borderColor={"#888"}
            borderSize={5}
          />
        </ScreenLoading>
      )}
    </Container>
  );
};

export default Detail;
