import React, { useState, useEffect } from "react";

import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import api from "../../../service/api";
import {
  Container,
  ContainerForm,
  Form,
  ButtunSubmit,
  Error,
  ScreenLoading,
} from "../styles";
import { FiAlertCircle } from "react-icons/fi";
import { FaArrowLeft } from "react-icons/fa";
import Loading from "../../../components/Loading";
import { IRequirement } from "../../../@types/types";

interface Props {
  location: {
    state: {};
  };

  match: {
    params: {
      id: number;
    };
  };
}

const Detail: React.FC<Props> = ({ match }) => {
  const { addToast } = useToasts();
  const history = useHistory();

  const [contentPage, setContentPage] = useState<IRequirement>();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (match.params.id) {
      (async () => {
        const dataDB = await api.get<any>(`/requirement/${match.params.id}`);

        setContentPage(dataDB.data);
        setLoading(false);
      })();
    }
  }, [match.params.id]);

  useEffect(() => {
    if (contentPage) {
      if (contentPage.id) formik.setFieldValue("id", contentPage.id);
      if (contentPage.description)
        formik.setFieldValue("description", contentPage.description);
      if (contentPage.value) formik.setFieldValue("value", contentPage.value);
    }
    // eslint-disable-next-line
  }, [contentPage]);

  async function handleSubmit(updates: any) {
    try {
      await api.put(`/requirement/${updates.id}`, updates);
      addToast("Setor alterado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/requisitos");
    } catch (error) {
      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  const formik = useFormik({
    initialValues: {
      id: undefined,
      value: undefined,
      description: undefined,
    },
    validationSchema: Yup.object({
      value: Yup.string().required("Nome é obrigatório"),
      description: Yup.string().required("Abreviação é obrigatório"),
    }),
    onSubmit: (data) => handleSubmit(data),
  });

  return (
    <Container>
      {!loading ? (
        <>
          <header>
            <button
              type="button"
              onClick={() => {
                history.goBack();
              }}
              className="arrowBack"
            >
              <FaArrowLeft size={18} />
            </button>
            <h2>Requisito</h2>
          </header>

          <ContainerForm>
            <Form onSubmit={formik.handleSubmit}>
              <div className="field">
                <label htmlFor="id">ID</label>
                <input
                  type="text"
                  style={{ borderStyle: "dashed" }}
                  className="id"
                  {...formik.getFieldProps("id")}
                  readOnly={true}
                />
              </div>

              <div className="field-group">
                <div className="field">
                  <label htmlFor="description">Descrição</label>
                  <input {...formik.getFieldProps("description")} />
                  {formik.touched.description && formik.errors.description ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.description} </span>
                    </Error>
                  ) : null}
                </div>
                <div className="field">
                  <label htmlFor="value">Valor</label>
                  <input id="value" {...formik.getFieldProps("value")} />
                  {formik.touched.value && formik.errors.value ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.value} </span>
                    </Error>
                  ) : null}
                </div>
              </div>

              <ButtunSubmit type="submit"> Alterar dados </ButtunSubmit>
            </Form>
          </ContainerForm>
        </>
      ) : (
        <ScreenLoading>
          <Loading
            colorLoading="#333"
            size={40}
            borderColor={"#888"}
            borderSize={5}
          />
        </ScreenLoading>
      )}
    </Container>
  );
};

export default Detail;
