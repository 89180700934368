import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { useFormik } from "formik";
import * as Yup from "yup";

import { FaArrowLeft } from "react-icons/fa";
import { FiAlertCircle } from "react-icons/fi";
import { IResponseItem } from "../../../@types/types";
import Loading from "../../../components/Loading";
import api from "../../../service/api";
import {
  ButtunSubmit,
  Container,
  ContainerForm,
  Error,
  Form,
  ScreenLoading,
} from "../styles";

interface Props {
  location: {
    state: {};
  };

  match: {
    params: {
      id: number;
    };
  };
}

const Detail: React.FC<Props> = ({ match }) => {
  const { addToast } = useToasts();
  const history = useHistory();

  const [contentPage, setContentPage] = useState<IResponseItem>();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (match.params.id) {
      (async () => {
        const dataDB = await api.get<any>(`/response_item/${match.params.id}`);

        setContentPage(dataDB.data);
        setLoading(false);
      })();
    }
  }, [match.params.id]);

  useEffect(() => {
    if (contentPage) {
      if (contentPage.id) formik.setFieldValue("id", contentPage.id);
      if (contentPage.description)
        formik.setFieldValue("description", contentPage.description);
      if (contentPage.value) formik.setFieldValue("value", contentPage.value);
      if (contentPage.obs === false || contentPage.obs === true)
        formik.setFieldValue("obs", contentPage.obs ? 1 : 0);
      if (contentPage.valid === false || contentPage.valid === true)
        formik.setFieldValue("valid", contentPage.valid ? 1 : 0);
    }
    // eslint-disable-next-line
  }, [contentPage]);

  async function handleSubmit(updates: any) {
    try {
      await api.put(`/response_item/${updates.id}`, {
        id: updates.id,
        description: updates.description,
        value: updates.value,
        obs: Boolean(Number(updates.obs)),
        valid: Boolean(Number(updates.valid)),
      });
      addToast("Setor alterado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/respostas");
    } catch (error) {
      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  const formik = useFormik({
    initialValues: {
      id: undefined,
      description: undefined,
      value: undefined,
      obs: undefined,
      valid: undefined,
    },
    validationSchema: Yup.object({
      description: Yup.string().required("Nome é obrigatório"),
      value: Yup.string().required("Abreviação é obrigatório"),
    }),
    onSubmit: (data) => handleSubmit(data),
  });

  return (
    <Container>
      {!loading ? (
        <>
          <header>
            <button
              type="button"
              onClick={() => {
                history.goBack();
              }}
              className="arrowBack"
            >
              <FaArrowLeft size={18} />
            </button>
            <h2>Respostas</h2>
          </header>

          <ContainerForm>
            <Form onSubmit={formik.handleSubmit}>
              <div className="field">
                <label htmlFor="id">ID</label>
                <input
                  type="text"
                  style={{ borderStyle: "dashed" }}
                  className="id"
                  {...formik.getFieldProps("id")}
                  readOnly={true}
                />
              </div>

              <div className="field">
                <label htmlFor="name">Descrição</label>
                <input {...formik.getFieldProps("description")} />
                {formik.touched.description && formik.errors.description ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.description} </span>
                  </Error>
                ) : null}
              </div>

              <div className="field-group">
                <div className="field">
                  <label htmlFor="obs">Observção</label>
                  <select {...formik.getFieldProps("obs")}>
                    <option value={1}>Sim</option>
                    <option value={0}>Não</option>
                  </select>
                </div>
                <div className="field">
                  <label htmlFor="valid">Valido</label>
                  <select {...formik.getFieldProps("valid")}>
                    <option value={1}>Sim</option>
                    <option value={0}>Não</option>
                  </select>
                </div>
              </div>
              <div className="field">
                <label htmlFor="value">Valor</label>
                <input {...formik.getFieldProps("value")} />
                {formik.touched.value && formik.errors.value ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.value} </span>
                  </Error>
                ) : null}
              </div>

              <ButtunSubmit type="submit"> Alterar dados </ButtunSubmit>
            </Form>
          </ContainerForm>
        </>
      ) : (
        <ScreenLoading>
          <Loading
            colorLoading="#333"
            size={40}
            borderColor={"#888"}
            borderSize={5}
          />
        </ScreenLoading>
      )}
    </Container>
  );
};

export default Detail;
