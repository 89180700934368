import React from "react";

import ItemsNav from "../Nav/ItemsNav";
import { Container } from "./styles";
import { NavbarHambuerguer } from "../NavbarTop/styles";

import Burguer from "../Nav/Burguer/index.jsx";

const Navbar: React.FC = () => {
  return (
    <Container className="nav-left">
      <>
        <NavbarHambuerguer>
          <Burguer />
        </NavbarHambuerguer>

        <ItemsNav />
      </>
    </Container>
  );
};

export default Navbar;
