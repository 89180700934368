import { IToken } from "../@types/types";

export const TOKEN_KEY = "@key-User";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => {
  const user = localStorage.getItem(TOKEN_KEY);

  return JSON.parse(user as string);
};

export const setToken = (token: IToken) => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
};

export const logout = () => {
  localStorage.clear();
};
