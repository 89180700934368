import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../service/api";
import * as authUser from "../../service/authUser";

import * as UserStorage from "../../service/localStorage/user";

import { IoMdAlert } from "react-icons/io";
import { FiLogIn } from "react-icons/fi";

import { Container, Content, ContainerError } from "./styles";
import Loading from "../../components/Loading";

import { IToken, IUser } from "../../@types/types";

interface ResponseSingInUser {
  user: IUser;
  token: IToken;
}

const Login: React.FC = () => {
  const history = useHistory();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState({
    password: false,
    email: false,
    message: "",
  });

  const [loading, setLoading] = useState({
    submit: false,
  });

  // function hadleInputChange(event: ChangeEvent<HTMLInputElement>) {
  // const { name, value } = event.target;
  // let data = value;
  // let tam = value.replace(/[^a-z0-9]/gi, "").length;
  // if (name === "cnpj") {
  //   if (tam <= 11) {
  //     //CPF
  //     console.log("CPF");
  //     data = data
  //       .replace(/\D/g, "")
  //       .replace(/(\d{3})(\d)/, "$1.$2")
  //       .replace(/(\d{3})(\d)/, "$1.$2")
  //       .replace(/(\d{2})(\d{1,2})/, "$1-$2")
  //       .replace(/(-\d{2})\d+?$/, "$1");
  //   } else {
  //     console.log("CNPJ");
  //     data = data
  //       .replace(/\D/g, "")
  //       .replace(/(\d{3})(\d)/, "$1.$2")
  //       .replace(/(\d{3})(\d)/, "$1.$2")
  //       .replace(/(\d{3})(\d{1,2})/, "$1-$2")
  //       .replace(/(-\d{2})\d+?$/, "$1");
  //   }
  // }
  // setFormData({
  //   ...data,
  //   [name]: data,
  // });
  // }

  async function hadleSubmit(event: React.FormEvent) {
    event.preventDefault();
    setLoading({ ...loading, submit: true });

    if (formData.email === "" && formData.password === "") {
      setLoading({ ...loading, submit: false });
      return setError({ ...error, password: true, email: true });
    }

    if (formData.email === "") {
      setLoading({ ...loading, submit: false });
      return setError({ ...error, email: true });
    }

    if (formData.password === "") {
      setLoading({ ...loading, submit: false });
      return setError({ ...error, password: true });
    }

    try {
      const response = await api.post<ResponseSingInUser>("/user/login", {
        email: formData.email,
        password: formData.password,
      });

      const { user, token } = response.data;

      const { id, email, firstname, lastname } = user;

      UserStorage.setUser({
        id,
        email,
        firstname,
        lastname,
      });

      const { access_token, exp, iat } = token;
      authUser.setToken({
        access_token,
        exp,
        iat,
      });
      window.location.reload();
      history.push("/inicio");
      setLoading({ ...loading, submit: false });
    } catch (error) {
      setLoading({ ...loading, submit: false });

      if (!error.response || error.response.status >= 404) {
        return setError({ ...error, message: "Erro interno" });
      }

      if (error.response.status === 403)
        return setError({ ...error, message: "Usuario não existe" });

      if (error.response.status === 401)
        return setError({ ...error, message: "Senha incorreta" });
    }
  }

  return (
    <Container>
      <Content>
        <header>
          <svg
            id="logo-alpar"
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1181.000000 528.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,528.000000) scale(0.100000,-0.100000)"
              fill={"#D21E26"}
              stroke="none"
            >
              <path
                d="M3886 4869 c-65 -16 -197 -83 -291 -150 -123 -87 -321 -288 -435
-440 -189 -252 -425 -681 -559 -1012 -51 -125 -73 -168 -109 -208 -41 -46
-129 -115 -137 -107 -1 1 1 50 6 107 16 184 -22 311 -124 413 -166 166 -554
204 -904 87 -238 -79 -493 -261 -493 -352 0 -84 48 -78 265 32 232 118 299
135 520 135 172 0 223 -11 307 -67 74 -50 108 -184 64 -251 -17 -27 -21 -28
-70 -23 -28 4 -88 13 -134 21 -348 64 -712 28 -958 -94 -252 -125 -423 -352
-424 -560 0 -222 163 -418 389 -466 88 -18 312 -18 431 1 306 49 514 145 890
410 83 59 153 105 155 103 2 -2 -3 -35 -11 -73 -10 -45 -14 -105 -12 -170 4
-110 21 -156 75 -207 135 -129 457 -63 807 165 49 32 90 56 92 54 5 -5 -175
-229 -376 -467 -478 -565 -710 -895 -821 -1166 -118 -289 -72 -447 129 -447
83 1 149 25 235 86 219 157 480 570 680 1077 111 280 180 416 312 615 84 126
149 204 207 251 l41 32 46 -36 c200 -152 570 -134 945 47 111 54 234 131 311
195 88 73 81 73 77 2 -15 -226 143 -429 378 -487 78 -20 350 -18 465 4 177 32
320 81 510 173 330 159 568 349 739 589 247 346 497 555 662 555 18 0 36 -4
39 -10 3 -5 -64 -98 -148 -207 -277 -355 -489 -662 -588 -851 -46 -87 -49
-135 -9 -177 57 -60 130 -54 226 21 215 167 629 413 920 547 1048 483 1990
534 2803 151 88 -42 117 -51 127 -43 25 20 15 34 -53 76 -271 165 -661 294
-1054 348 -161 22 -523 30 -694 16 -546 -47 -1086 -205 -1633 -480 -57 -29
-100 -46 -96 -39 164 278 445 548 729 700 115 62 140 90 115 135 -30 54 -25
53 -575 51 -575 -3 -551 0 -601 -81 -122 -195 -295 -437 -312 -437 -4 0 -7 69
-7 153 0 150 0 153 -32 220 -63 133 -172 212 -348 253 -123 29 -387 26 -515
-5 -222 -54 -405 -148 -542 -279 -71 -68 -78 -78 -78 -114 0 -32 4 -40 24 -48
37 -14 84 3 256 91 168 86 251 112 409 130 139 15 272 -2 362 -47 107 -54 150
-166 104 -276 -11 -28 -38 -30 -152 -9 -439 83 -837 36 -1094 -130 -45 -29
-60 -35 -55 -22 33 85 44 220 26 301 -36 156 -168 283 -340 327 -183 47 -493
-2 -747 -120 -42 -19 -78 -34 -80 -32 -2 2 13 44 32 92 40 98 37 110 -25 99
-84 -14 -199 -119 -304 -279 -33 -50 -88 -134 -124 -188 -45 -68 -115 -149
-232 -270 -261 -268 -427 -403 -615 -497 -163 -82 -267 -98 -317 -48 -19 19
-20 28 -14 103 11 147 66 277 159 373 23 24 105 95 182 157 337 271 626 547
789 750 196 246 360 528 433 742 29 87 37 128 41 209 5 121 -11 184 -61 232
-53 51 -115 63 -211 41z m37 -270 c17 -27 -28 -147 -105 -280 -167 -292 -418
-621 -642 -843 -89 -88 -316 -279 -323 -272 -7 6 62 176 107 266 88 177 269
458 431 670 152 199 342 392 443 451 55 31 73 33 89 8z m960 -1408 c251 -123
32 -498 -416 -711 -274 -131 -481 -154 -585 -64 -82 70 -87 187 -14 329 84
161 308 331 552 420 120 43 186 54 305 52 93 -2 118 -6 158 -26z m-3258 -406
c95 -20 170 -58 184 -94 34 -81 -64 -218 -225 -312 -155 -91 -273 -125 -469
-135 -187 -10 -302 22 -353 97 -31 44 -25 109 18 195 70 142 222 233 445 264
82 12 314 3 400 -15z m4538 -6 c146 -22 229 -57 247 -105 20 -52 -24 -148
-102 -220 -49 -47 -169 -122 -247 -153 -203 -84 -495 -101 -627 -36 -72 35
-99 79 -91 148 22 180 197 325 444 367 72 12 294 11 376 -1z m-3205 -1296
c-229 -448 -420 -757 -561 -910 -109 -118 -178 -79 -111 63 62 132 449 665
702 967 36 42 66 77 68 77 2 0 -42 -89 -98 -197z"
              />
              <path
                d="M11285 2595 c-39 -38 -28 -93 22 -114 51 -21 103 13 103 69 0 29 -44
70 -75 70 -14 0 -37 -11 -50 -25z"
              />
            </g>
          </svg>
        </header>

        <main>
          <h1>Avaliação departamental</h1>
          <p>Realize seu acesso, para analisar a avaliação.</p>

          <form onSubmit={hadleSubmit}>
            <div className="field">
              <input
                type="text"
                name="cod"
                className={error.email ? "error-input" : ""}
                onChange={(e) => {
                  setFormData({ ...formData, email: e.target.value });
                  e.target.value !== "" && setError({ ...error, email: false });
                }}
                value={formData.email}
              />
              <label
                className={
                  formData.email
                    ? error.email
                      ? "active-label-error"
                      : "active-label"
                    : ""
                }
                htmlFor="cod"
              >
                E-mail
              </label>

              {error.email && (
                <div className="error-icon">
                  <IoMdAlert />
                </div>
              )}
            </div>
            <div className="field">
              <input
                type="password"
                name="cod"
                className={error.password ? "error-input" : ""}
                onChange={(e) => {
                  setFormData({ ...formData, password: e.target.value });
                  e.target.value !== "" &&
                    setError({ ...error, password: false });
                }}
                value={formData.password}
              />
              <label
                className={
                  formData.password
                    ? error.password
                      ? "active-label-error"
                      : "active-label"
                    : ""
                }
                htmlFor="cod"
              >
                Senha
              </label>

              {error.password && (
                <div className="error-icon">
                  <IoMdAlert />
                </div>
              )}
            </div>

            {error.message && (
              <ContainerError>
                <IoMdAlert />
                <span>{error.message}</span>
              </ContainerError>
            )}

            <button className="buttonSubmit" type="submit">
              <span>
                <FiLogIn />
              </span>
              <strong>
                {loading.submit ? (
                  <Loading
                    size={30}
                    borderSize={4}
                    colorLoading="rgba(255,255,255)"
                    borderColor="rgba(255,255,255, 0.3)"
                  />
                ) : (
                  "Entrar"
                )}
              </strong>
            </button>
          </form>
        </main>
      </Content>
    </Container>
  );
};

export default Login;
