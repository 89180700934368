import React from "react";

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";

import * as authUser from "./service/authUser";

import { Grid } from "./styles/global";

import NavbarTop from "./components/NavbarTop";
import NavbarLeft from "./components/NavbarLeft";

import PanelSectors from "./pages/panel/PanelSectors";
import Sector from "./pages/detail/Sector";
import CreateSector from "./pages/create/CreateSector";

import PanelRequirements from "./pages/panel/PanelRequirements";
import Requirement from "./pages/detail/Requirement";
import CreateRequirement from "./pages/create/CreateRequirement";

import PanelResponseItems from "./pages/panel/PanelResponseItems";
import ResponseItem from "./pages/detail/ResponseItem";
import CreateResponseItem from "./pages/create/CreateResponseItem";

import PanelDepartamentalEvaluation from "./pages/panel/PanelDepartamentalEvaluation";
import DepartamentalEvaluation from "./pages/detail/DepartamentalEvaluation";
import AnalyticDepartamentalEvaluation from "./pages/analytic/DepartamentalEvaluation";
import CreateDepartamentalEvaluation from "./pages/create/CreateDepartamentalEvaluation";

import Login from "./pages/Login";

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  ...rest
}: any) => {
  const routeComponent = (props: any) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          authUser.isAuthenticated() ? (
            <Grid>
              <NavbarTop />
              <NavbarLeft />
              <Component {...props} />
            </Grid>
          ) : (
            <Redirect to="/" />
          )
        }
      />
    );
  };
  return <Route {...rest} render={routeComponent} />;
};

const PublicRoute = ({
  component: Component,
  isAuthenticated,
  ...rest
}: any) => {
  const routeComponent = (props: any) => (
    <Route
      {...rest}
      render={(props) =>
        authUser.isAuthenticated() ? (
          <Redirect to="/inicio" />
        ) : (
          <Component seller {...props} />
        )
      }
    />
  );
  return <Route {...rest} render={routeComponent} />;
};

function Routes() {
  return (
    <BrowserRouter>
      <ToastProvider>
        <Switch>
          <PrivateRoute component={() => null} exact path="/inicio" />

          {/* Avaliação departamental */}
          <PrivateRoute
            component={PanelDepartamentalEvaluation}
            exact
            path="/avaliacoes"
          />
          <PrivateRoute
            component={DepartamentalEvaluation}
            exact
            path="/avaliacao/:id"
          />
          <PrivateRoute
            component={CreateDepartamentalEvaluation}
            exact
            path="/criar/avaliacao"
          />
          <PrivateRoute
            component={AnalyticDepartamentalEvaluation}
            exact
            path="/analise/avaliacao/:id"
          />

          {/* // */}

          {/* Setor */}
          <PrivateRoute component={PanelSectors} exact path="/setores" />
          <PrivateRoute component={Sector} exact path="/setor/:id" />
          <PrivateRoute component={CreateSector} exact path="/criar/setor" />
          {/* // */}

          {/* Requisito */}
          <PrivateRoute
            component={PanelRequirements}
            exact
            path="/requisitos"
          />
          <PrivateRoute component={Requirement} exact path="/requisito/:id" />
          <PrivateRoute
            component={CreateRequirement}
            exact
            path="/criar/requisito"
          />
          {/* // */}

          {/* Resposta por item */}
          <PrivateRoute
            component={PanelResponseItems}
            exact
            path="/respostas"
          />
          <PrivateRoute component={ResponseItem} exact path="/resposta/:id" />
          <PrivateRoute
            component={CreateResponseItem}
            exact
            path="/criar/resposta"
          />
          {/* // */}

          <PublicRoute component={Login} exact path="/" />
          <Route
            component={() => (
              <div
                style={{
                  height: "100vh",
                  width: "100vw",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <p style={{ fontSize: 64 }}>Erro 404</p>
              </div>
            )}
            exact
            path="*"
          />
        </Switch>
      </ToastProvider>
    </BrowserRouter>
  );
}

export default Routes;
