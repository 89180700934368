import styled from "styled-components";

export const Container = styled.div`
  grid-area: NAVTOP;
  display: flex;
  background-color: #fafafa;
  border-bottom: 2px solid ${(props) => props.theme.colors.primary};

  /* position: fixed;
  width: 100vw; */
  align-items: center;
  justify-content: space-between;

  .logo {
    height: 35px;
    width: 35px;
    margin-left: 8px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 900px) {
    justify-content: space-between;
  }
`;

export const InfoUser = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: flex-end;

  margin-right: 10px;
  border-radius: 8px;

  z-index: 6;
  cursor: pointer;
  overflow: hidden;

  .info-user {
    display: flex;
    flex-direction: column;

    align-items: flex-end;
    justify-content: center;

    margin-right: 20px;

    h3 {
      font-size: 18px;
    }

    span {
      font-size: 14px;
      color: #555;
    }
  }

  .icon-user {
    height: 45px;
    width: 45px;

    background: #333;
    border-radius: 50%;
    display: flex;

    align-items: center;
    justify-content: center;
  }

  .triangulo {
    margin: 5px 5px 0 5px;

    transition: 0.4s;
    transform: rotate(0deg);
  }

  .triangulo-active {
    margin: 5px 5px 0 5px;
    transform: rotate(180deg);

    transition: 0.4s;
  }

  &:active:hover {
    background: none;
  }

  @media (max-width: 901px) {
    .info-user {
      margin-right: 0px;

      h3 {
        display: none;
      }

      span {
        display: none;
      }
    }
  }
`;

export const OptionNav = styled.ul`
  box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.59);

  position: absolute;
  top: 53px;
  right: -95px;
  width: 250px;
  border-radius: 5px;

  background-color: #fff;
  transform: translateX(-45%);
  overflow: hidden;
  list-style: none;
  z-index: 5;

  li {
    display: flex;
    align-items: center;
    transition: all 0.3s;

    padding: 12px 15px;
    width: 100%;

    svg {
      margin-right: 10px;
    }
  }

  li:hover {
    background-color: #ccc;
  }

  a {
    text-decoration: none;
    color: #333;
  }

  animation: fadeout 0.5s;

  @keyframes fadeout {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

export const NavbarHambuerguer = styled.div``;
