import React from "react";

import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";

import { Formik } from "formik";
import { FiAlertCircle } from "react-icons/fi";
import { FaArrowLeft } from "react-icons/fa";
import * as Yup from "yup";

import api from "../../../service/api";
import { Container, ContainerForm, Form, ButtunSubmit, Error } from "../styles";

const CreateUser: React.FC = () => {
  const { addToast } = useToasts();
  const history = useHistory();

  async function handleSubmit(create: any) {
    try {
      await api.post("/requirement", {
        value: create.value,
        description: create.description,
      });

      addToast("Requisitos criado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/requisitos");
    } catch (error) {
      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <Container>
      <header>
        <button
          type="button"
          onClick={() => {
            history.goBack();
          }}
          className="arrowBack"
        >
          <FaArrowLeft size={18} />
        </button>
        <h2>Criar Requisito</h2>
      </header>

      <ContainerForm>
        <Formik
          initialValues={{
            value: undefined,
            description: undefined,
          }}
          validationSchema={Yup.object({
            value: Yup.string().required("Valor é obrigatório"),
            description: Yup.string().required("Descrição é obrigatório"),
          })}
          onSubmit={(data) => handleSubmit(data)}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <div className="field-group">
                <div className="field">
                  <label htmlFor="description">Descrição</label>
                  <input {...formik.getFieldProps("description")} />
                  {formik.touched.description && formik.errors.description ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.description} </span>
                    </Error>
                  ) : null}
                </div>
                <div className="field">
                  <label htmlFor="value">Valor</label>
                  <input {...formik.getFieldProps("value")} />
                  {formik.touched.value && formik.errors.value ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.value} </span>
                    </Error>
                  ) : null}
                </div>
              </div>

              <ButtunSubmit type="submit"> Criar </ButtunSubmit>
            </Form>
          )}
        </Formik>
      </ContainerForm>
    </Container>
  );
};

export default CreateUser;
