import {
  Form as FormDevolution,
  ContainerForm as ContainerFormDevolution,
  Error as ErrorDevolution,
} from "../create/styles";

import styled from "styled-components";

export const Container = styled.div`
  grid-area: APP;
  padding: 10px 30px;

  height: 100%;
  max-height: 100vh;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #333;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #888;
  }

  header {
    margin-bottom: 20px;

    display: flex;
    flex-direction: row;

    .arrowBack {
      display: flex;
      justify-content: center;
      align-items: center;

      margin-right: 10px;

      height: 30px;
      width: 30px;

      border-radius: 50%;

      transition: all 0.4s;

      &:hover {
        background-color: #ccc;
      }
    }

    .reportDownload {
      margin-left: 20px;
    }

    @media (max-width: 900px) {
      margin-left: 0px;
      justify-content: space-between;

      .reportDownload {
        margin-left: 0;
      }
    }
  }
`;

export const InfoDevolution = styled.div`
  margin-bottom: 20px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);

  h2 {
    font-size: 19px;
    color: #111;
    font-weight: 500;
  }

  h3 {
    font-size: 18px;
    color: #333;
    margin-top: 2px;
  }

  .line {
    height: 1px;
    width: 100%;
    background-color: #777;
    margin-bottom: 5px;
  }

  h4 {
    font-size: 14px;
    color: #333;
    margin-top: 8px;

    &:first-child {
      margin-top: 0;
    }
  }

  .reportDownload {
    background-color: transparent;
  }

  .headerDevoltion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    height: 45px;
    background-color: #ccc;

    a {
      display: flex;
      align-items: center;
      text-align: center;
      text-decoration: none;

      background-color: ${(props) => props.theme.colors.primary};
      padding: 3px 4px;
      border-radius: 6px;

      color: #fff;

      span {
        font-size: 1rem;
        font-weight: 700;
      }

      transition: all 0.2s;

      &:hover {
        background-color: ${(props) => props.theme.colors.tertiary};
      }
    }

    .containerActions {
      position: relative;
      &:hover .itemsActions {
        display: block;
      }

      .buttonActions {
        background-color: #fff;
        padding: 5px 8px;
        background: transparent;
        border: 2px solid #333;

        border-radius: 6px;
        font-weight: bold;
        font-size: 14px;
      }

      .itemsActions {
        display: none;
        position: absolute;
        right: 0;

        box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.4);
        border-radius: 8px;
        background: #fff;
        padding: 6px 10px;
        white-space: nowrap;

        li {
          background: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #f00;
          z-index: 1;
          cursor: pointer;

          svg {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .bodyDevoltion {
    background-color: #fff;
    padding: 10px 10px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .conatinerInfo {
      flex: 1;
      min-width: 300px;
      padding: 0 10px;

      div {
        padding: 0 10px;
        margin-bottom: 15px;

        h4 {
          font-size: 17px;
          margin-bottom: 5px;
        }

        p {
          font-weight: 400;
          font-size: 16px;
          color: #333;
        }

        span {
          font-weight: 400;
          font-size: 14px;
          color: #555;
        }
      }
    }
  }

  .bodyChat {
    padding: 10px 15px;
    margin: 0;

    max-height: 300px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;

    background: #fff;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: #999;
      border-radius: 10px;
      width: 4px;
    }

    ::-webkit-scrollbar-track {
      background: none;
    }

    .containerchat {
      height: auto;
      width: 70%;

      margin-bottom: 0;
      margin-top: 10px;

      span {
        font-weight: 700;
      }

      p {
        width: 100%;
        height: auto;
        white-space: pre-wrap;
        padding: 8px 0 0 0;
      }
    }

    .containerMessage {
      padding: 5px 8px;
      margin-bottom: 5px;
    }

    .i {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 0;
      margin-bottom: 2px;

      .containerMessage {
        background-color: #ededed;
        border-radius: 10px 0 10px 10px;
      }

      header {
        text-align: right;
      }

      p {
        padding: 4px 0;
      }
    }

    .to {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding: 0;
      margin-bottom: 3px;

      .containerMessage {
        border: 2px solid #c7c7c7;
        border-radius: 0 10px 10px 10px;
      }
    }

    header {
      padding: 0;
      margin: 0;
    }

    footer {
      width: 100%;

      padding: 0 10px 0 0;

      display: flex;
      justify-content: flex-end;

      font-size: 12px;
      font-weight: 400;
      color: #555;
    }
  }

  footer {
    background: #fff;
    padding: 5px 10px 10px 10px;

    div {
      /* height: 40px; */
      width: 100%;

      display: flex;
      justify-content: space-between;
      align-items: center;

      input,
      textarea {
        width: 95%;
        height: 46px;
        resize: vertical;

        padding: 10px 25px;
        margin-right: 10px;
        border-radius: 10px;

        font-size: 18px;
        border: 2px solid #888;

        ::-webkit-scrollbar {
          width: 6px;
        }

        ::-webkit-scrollbar-thumb {
          background: #999;
          border-radius: 10px;
          width: 4px;
        }

        ::-webkit-scrollbar-track {
          background: none;
        }
      }

      button {
        height: 40px;
        width: 40px;

        display: flex;
        justify-content: center;
        align-items: center;

        padding: 0 0 0 4px;

        border-radius: 50%;
        background-color: #d21e26;
        box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.4);
      }
    }
  }

  .containerImg {
    display: flex;
    flex-wrap: wrap;

    align-items: center;
    justify-content: flex-start;
    img {
      max-width: 140px;
      max-height: 100px;

      border-radius: 4px;
      margin-right: 8px;
      margin-bottom: 8px;

      transition: all 150ms linear;

      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        /* box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.6); */
        transform: scale(1.1);
      }
    }
  }
`;

export const InfoStatus = styled.div`
  width: 100%;
  margin-bottom: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  .containerStatus {
    position: relative;
    height: 72px;
    flex: 1;
  }

  .bannerLine {
    height: 5px;
    width: 100%;
    background-color: rgba(136, 227, 138, 0.5);
    border-radius: 4px 4px 0 0;
    position: absolute;
  }

  .containerStatus {
    margin-right: 8px;
    margin-bottom: 8px;
  }

  /* .containerStatus:last-child {
    margin-right: 0px;
  } */

  .box {
    flex: 1;
    min-width: 240px;
    height: 100%;
    padding: 8px 14px;
    border-radius: 4px;
    box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.3);
    background-color: #fff;

    div {
      padding: 5px 0;
      h5 {
        font-size: 12px;
        color: #333;
      }
    }

    p {
      font-size: 15px;
      font-weight: 500;
    }

    span {
      font-size: 13px;
      font-weight: 400;
      border-radius: 4px;
      padding: 2px 5px;
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;

    .containerStatus {
      margin-left: 0;
      margin-bottom: 0px;
      margin-top: 10px;
    }
  }
`;

export const Form = styled(FormDevolution)``;

export const ContainerForm = styled(ContainerFormDevolution)``;

export const ButtunSubmit = styled.button`
  width: 100%;
  height: 50px;
  margin-top: 20px;

  font-weight: 700;
  font-size: 16px;
  color: #fff;

  background: ${(props) => props.theme.colors.primary};
  border-radius: 4px;

  &:hover {
    opacity: 0.85;
  }
`;

export const ButtunTable = styled.button`
  height: 100%;

  padding: 8px;
  font-weight: 700;
  font-size: 16px;
  color: #fff;

  background: #d21e26;
  border-radius: 4px;

  &:first-child {
    margin-right: 8px;
  }

  &:hover {
    opacity: 0.85;
  }
`;

export const Error = styled(ErrorDevolution)``;

export const ContainerDropZone = styled.div`
  width: 100%;
`;

export const ContainerPreviews = styled.ul`
  margin-top: 25px;
  width: 100%;

  display: flex;
  justify-content: flex-start;

  max-width: 73vw;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  li {
    margin-right: 20px;
    img {
      border-radius: 10px;
      height: 80px;
    }

    .fileInfo {
      margin-left: 5px;
      display: flex;
      flex-direction: column;

      button {
        margin-top: 5px;
        background: none;
        color: #f00;
        width: 20px;
      }
    }
  }
`;

export const ContainerCheck = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  height: 40px;

  border: 2px solid #d21e26;
  border-radius: 4px;
  overflow: hidden;

  button {
    border-radius: 0px;
    flex: 1;

    background: #fff;
    font-weight: bold;
    color: #333;
  }

  button:first-child {
    border-right: 2px solid #d21e26;
  }

  button:last-child {
    border-left: 2px solid #d21e26;
  }

  .check {
    background: #d21e26;
    color: #fff;
  }
`;

export const ContainerCheckGrey = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  height: 40px;

  border: 2px solid #555;
  border-radius: 4px;
  overflow: hidden;

  button {
    border-radius: 0px;
    flex: 1;

    background: #fff;
    font-weight: bold;
    color: #333;
  }

  button:first-child {
    border-right: 2px solid #555;
  }

  button:last-child {
    border-left: 2px solid #555;
  }

  .check {
    background: #555;
    color: #fff;
  }
`;

export const TableContainer = styled.div`
  width: 100%;

  max-width: 100vw;
  overflow-y: auto;

  /* ::-webkit-scrollbar {
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #333;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #eee;
  } */

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 10;

    thead {
      background-color: #eee;

      tr {
        th {
          z-index: 2;
          background-color: #eee;
          position: sticky;
          top: 0;
          padding: 6px 8px;
          font-size: 16px;
          color: #444;
          white-space: nowrap;
        }
      }
    }

    tbody {
      background-color: #fff;

      tr {
        color: #777;
        border-bottom: 2px solid #eee;
        cursor: pointer;

        &:hover {
          background-color: #eee;
        }

        td {
          position: relative;
          height: 35px;
          text-align: center;
          padding: 0 6px;

          .notification {
            display: flex;
            align-items: center;
            position: absolute;
            left: 8px;
            top: 0px;
            height: 100%;
          }
          .status {
            color: #000;
            font-size: 13px;
            font-weight: 500;
            border-radius: 4px;
            padding: 2px 5px;
            white-space: nowrap;
          }

          .stuationContainer {
            width: 100%;

            button {
              width: 40px;
              margin: 0 auto;

              background: transparent;
            }

            div {
              width: 40px;
              margin: 0 auto;
            }

            svg {
              transition: transform 0.2s ease-in-out;

              &:hover {
                transform: scale(1.3);
              }
            }
          }
        }
      }
    }
  }
`;

export const ContainerList = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  .headerTable {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    height: 45px;
    background-color: #ccc;

    h3 {
      font-size: 18px;
      color: #111;
      font-weight: 500;
    }

    button {
      padding: 8px 10px;
      height: 28px;
      background-color: #d21e26;
      border-radius: 5px;

      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: all 0.3s;

      &:hover {
        opacity: 0.6;
      }

      svg {
        color: #fff;
        margin-right: 2px;
      }

      span {
        font-size: 12px;
        color: #fff;
        font-weight: 500;
      }

      @media (max-width: 550px) {
        span {
          display: none;
        }
      }
    }
  }
`;

export const FooterContainer = styled.div`
  height: 55px;
  width: 100%;
  padding: 10px 10px;
  margin-bottom: 20px;

  background-color: #fff;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 550px) {
    height: 80px;
  }

  nav {
    display: flex;
    height: 100%;

    justify-content: space-between;
  }

  span {
    padding-bottom: 10px;
    font-size: 12px;
    font-weight: 400;
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (max-width: 500px) {
      flex-direction: column;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;

      height: 30px;
      padding: 15px 15px;
      margin-right: 10px;

      font-weight: 700;
      background-color: #fff;
      transition: all 0.8s;
      color: #555;
    }

    button:hover {
      background-color: #ccc;
    }

    .buttonNull {
      cursor: not-allowed;
    }
  }
`;

export const Infogroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "FP HT"
    "FP HT";
  grid-gap: 0px 15px;

  div {
    box-shadow: none;
  }

  @media (max-width: 950px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    grid-template-areas:
      "FP"
      "HT";

    div {
      width: 100%;
    }
  }
`;

export const PaymentDetailCard = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 10px 10px;
  margin-bottom: 15px;
`;
export const HeaderPaymentDetailCard = styled.div`
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    padding: 0 10px;

    h4 {
      color: #008000;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      color: #333;
    }

    span {
      font-weight: 400;
      font-size: 14px;
      color: #555;
    }
  }
`;
export const BodyPaymentDetailCard = styled.div`
  /* display: none; */
  div {
    padding: 0 10px;
    margin-bottom: 10px;
    p {
      font-weight: 400;
      font-size: 16px;
      color: #333;
    }

    span {
      font-weight: 400;
      font-size: 14px;
      color: #555;
    }
  }
`;

export const ScreenLoading = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SlaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 100px;
  flex: 1;
  height: 100%;

  .boxSla {
    position: relative;
    overflow: hidden;

    height: 65%;
    max-height: 23px;
    width: 90%;

    background-color: #fbfbfb;
    border-radius: 8px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);

    .percentageSla {
      position: absolute;
      margin: auto;

      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      span {
        font-size: 12px;
        font-weight: 700;
        color: #444;
      }
    }

    .valueSla {
      height: 100%;
      width: 25%;

      /* background-color: rgba(255, 220, 0, 0.6); */
      /* background-color: rgba(255, 61, 61, 0.8); */
      background-color: rgba(90, 182, 95, 0.8);
    }
  }
`;

export const PopUpConfirmation = styled.div`
  width: 100%;
  height: 100%;
  padding: 35px 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .headerPopUp {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    nav {
      display: flex;
      justify-content: center;

      margin-bottom: 35px;
    }

    span {
      font-size: 18px;
      color: #555;
      font-weight: 600;
      padding: 0 15px;
      text-align: center;
      margin-bottom: 4px;
    }

    .field {
      margin-top: 10px;
      padding: 10px 30px;
      width: 100%;
      max-width: 550px;

      label {
        font-size: 14px;
        margin-bottom: 4px;
        color: #555;
      }

      textarea {
        padding: 10px 10px;
        background: #fff;
        height: 80px;
        width: 100%;
        font-size: 16px;
        color: #000;
        border: 2px solid #aaa;
        border-radius: 5px;
        /* resize: vertical; */
        resize: unset;

        ::-webkit-scrollbar {
          width: 8px;
        }

        ::-webkit-scrollbar-thumb {
          background: #333;
          width: 4px;
        }

        ::-webkit-scrollbar-track {
          background: #888;
        }

        &:focus {
          border: 2px solid #333;
        }
      }
    }
  }

  .buttonsPopUp {
    width: 100%;
    display: flex;
    justify-content: space-between;

    button {
      flex: 1;
      border-radius: 40px;
      padding: 10px 4px;
      font-weight: 700;
      font-size: 14px;
      border: 1.5px solid rgba(97, 97, 97, 0.4);

      transition: all 0.4s;

      &:nth-child(1) {
        margin: 0 0 0 40px;
        background-color: #fafafa;

        &:hover {
          opacity: 0.8;
        }
      }

      &:nth-child(2) {
        margin: 0 40px 0 30px;
        background-color: #1aa33f;
        color: #fff;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
`;

export const ReportDownload = styled.div`
  position: relative;
  cursor: pointer;

  .icon-execel {
    display: flex;
    justify-content: center;
    align-items: center;

    .row-excel {
      transition: 0.4s;
      transform: rotate(0deg);
    }

    .row-excel-active {
      transition: 0.4s;
      transform: rotate(180deg);
    }
  }

  .optionReport {
    animation: fadeout 0.4s;

    @keyframes fadeout {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
    box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.59);

    position: absolute;
    top: 30px;
    right: -10px;
    width: 220px;
    border-radius: 5px;

    background-color: #fff;
    overflow: hidden;
    list-style: none;
    z-index: 5;

    li {
      display: flex;
      align-items: center;
      transition: all 0.3s;

      padding: 8px 15px;
      width: 100%;

      border-bottom: 1px solid #bbb;

      &:last-child {
        border-bottom: none;
      }

      .icon-execel {
        margin-right: 8px;
      }

      span {
        font-size: 14px;
      }
    }

    li:hover {
      background-color: #ccc;
    }
  }
`;
