import React from "react";

import { Container } from "./styles";

import NavUserInfo from "../NavUserInfo";

const NavbarTop: React.FC = () => {
  return (
    <Container>
      <div className="logo">
        <svg
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="245.000000pt"
          height="231.000000pt"
          viewBox="0 0 245.000000 231.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,231.000000) scale(0.100000,-0.100000)"
            fill="#D0222D"
            stroke="none"
          >
            <path
              d="M1211 1714 c-162 -27 -322 -92 -459 -187 -98 -67 -152 -129 -152
-174 0 -64 38 -57 253 47 203 98 228 104 432 105 157 0 174 -2 220 -23 94 -43
135 -101 135 -191 0 -33 -6 -56 -19 -72 l-18 -23 -144 22 c-198 30 -323 36
-460 23 -358 -34 -620 -183 -736 -418 -37 -74 -38 -80 -38 -183 0 -99 2 -109
33 -173 38 -77 108 -151 180 -190 78 -42 153 -57 290 -57 237 0 450 49 648
151 101 51 406 249 487 317 25 20 85 54 134 76 48 21 107 52 130 68 l43 29 0
244 c0 210 -2 245 -15 245 -8 0 -24 -19 -36 -42 -12 -24 -29 -53 -39 -65 -21
-27 -121 -113 -132 -113 -4 0 -5 46 -2 103 10 166 -21 269 -108 357 -115 116
-372 167 -627 124z m80 -728 c72 -16 137 -45 159 -71 38 -45 -4 -141 -97 -225
-69 -62 -228 -142 -328 -166 -91 -22 -320 -25 -389 -5 -69 20 -126 76 -126
124 0 67 39 147 100 208 110 111 226 148 460 148 97 1 180 -5 221 -13z"
            />
          </g>
        </svg>
      </div>

      <NavUserInfo />
    </Container>
  );
};

export default NavbarTop;
