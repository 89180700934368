import styled, { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0 !important;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
  }

  body {
    background: #F0F0F0;
    -webkit-font-smoothing: antialiased;
  }

  html, body, #root {
    height: 100%;
    ::-webkit-scrollbar {
    width: 8px;
  }

  ul {
    list-style-type: none;
  }


  }

  *, button, input {
    border: 0;
    outline: 0;

    font-family: 'Roboto', sans-serif;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  button {
    cursor: pointer;
  }

  input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

`;

export const Grid = styled.div`
  display: grid;
  height: 100vh;

  grid-template-columns: 100px auto;
  grid-template-rows: 50px auto;

  grid-template-areas:
    "NAV NAVTOP"
    "NAV APP";

  /* @media (max-width: 900px) {
    grid-template-columns: 0px auto;
    grid-template-rows: 50px auto;
  } */
`;
