import axios from "axios";
import * as authUser from "./authUser";

interface IToken {
  access_token: string;
  iat: number;
  exp: number;
}

const api = axios.create({
  baseURL:
    process.env.NODE_ENV !== "development"
      ? "https://api-avaliacaodepartamental.alpardobrasil.com.br"
      : "http://localhost:6920",
});

if (authUser.getToken() !== null) {
  api.interceptors.request.use(async (config) => {
    const { access_token, exp } = authUser.getToken() as unknown as IToken;

    const now = new Date();
    const tokenExp = new Date(exp * 1000);

    if (now < tokenExp) {
      if (access_token) {
        config.headers["x-access-token"] = `Bearer ${access_token}`;
      } else {
        localStorage.clear();
        window.location.reload();
      }
    } else {
      localStorage.clear();
      window.location.reload();
    }
    return config;
  });
}

export default api;
