const theme = {
  colors: {
    background: "#F0F0F0",
    text: "#e1e1e6",
    primary: "#4fb0a5",
    primaryOpacity: "rgba(79, 176, 165, 0.3)",
    secondary: "#3b877f",
    tertiary: "#449c93",
    // tertiary: "#dff0ee",
    error: "#d93025",
  },
  sizes: {
    normalFontSize: "1rem",
    smallFontSize: ".75rem",
  },
};

export default theme;
