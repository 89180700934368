import styled from "styled-components";

export const Container = styled.div`
  grid-area: NAV;
  background: #fff;

  height: 100%;
  padding: 0 0 40px 0;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  .logoContainer {
    width: 100%;

    display: flex;
    justify-content: center;
    padding: 15px 0 10px 0;

    cursor: pointer;
  }

  img {
    width: 45%;
  }
`;

export const OptionNav = styled.ul`
  width: 95%;
  margin-top: 10px;

  border-radius: 8px;
  background-color: #f0f0f0;
  overflow: hidden;
  list-style: none;
  cursor: pointer;

  li {
    display: flex;
    align-items: center;
    transition: all 0.3s;

    padding: 12px 15px;
    width: 100%;

    span {
      color: #333;
    }

    svg {
      margin-right: 10px;
    }
  }

  li:hover {
    background-color: #ccc;
  }

  a {
    text-decoration: none;
    color: #333;
  }
`;
