import { IUser } from "../../@types/types";

export const STORAGE = "@Data-User";

export const getUser = () => {
  const user = localStorage.getItem(STORAGE);

  return JSON.parse(user as string);
};

export const setUser = (user: IUser) => {
  localStorage.setItem(STORAGE, JSON.stringify(user));
};

export const removeUser = () => {
  localStorage.removeItem(STORAGE);
};
