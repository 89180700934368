import React from "react";

import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";

import { Formik } from "formik";
import { FiAlertCircle } from "react-icons/fi";
import { FaArrowLeft } from "react-icons/fa";
import * as Yup from "yup";

import api from "../../../service/api";
import { Container, ContainerForm, Form, ButtunSubmit, Error } from "../styles";

const CreateUser: React.FC = () => {
  const { addToast } = useToasts();
  const history = useHistory();

  async function handleSubmit(create: any) {
    try {
      await api.post("/sector", {
        name: create.name,
        abbreviation: create.abbreviation,
      });

      addToast("Setor criado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/setores");
    } catch (error) {
      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <Container>
      <header>
        <button
          type="button"
          onClick={() => {
            history.goBack();
          }}
          className="arrowBack"
        >
          <FaArrowLeft size={18} />
        </button>

        <h2>Criar Setor</h2>
      </header>

      <ContainerForm>
        <Formik
          initialValues={{
            name: undefined,
            abbreviation: undefined,
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Nome é obrigatório"),
            abbreviation: Yup.string().required("Abreviação é obrigatório"),
          })}
          onSubmit={(data) => handleSubmit(data)}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <div className="field-group">
                <div className="field">
                  <label htmlFor="name">Nome</label>
                  <input {...formik.getFieldProps("name")} />
                  {formik.touched.name && formik.errors.name ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.name} </span>
                    </Error>
                  ) : null}
                </div>
                <div className="field">
                  <label htmlFor="abbreviation">Abreviação</label>
                  <input {...formik.getFieldProps("abbreviation")} />
                  {formik.touched.abbreviation && formik.errors.abbreviation ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.abbreviation} </span>
                    </Error>
                  ) : null}
                </div>
              </div>

              <ButtunSubmit type="submit"> Criar </ButtunSubmit>
            </Form>
          )}
        </Formik>
      </ContainerForm>
    </Container>
  );
};

export default CreateUser;
