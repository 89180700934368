import styled from "styled-components";
import { Form as FormDevolution } from "../create/styles";

export const Container = styled.div`
  grid-area: APP;
  padding: 10px 30px;

  height: 100%;
  max-height: 100vh;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #333;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #888;
  }

  header {
    margin-bottom: 20px;
  }
`;

export const Filter = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 35px;

  position: relative;

  margin-bottom: 20px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px 0 0 4px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 1;
    width: 40px;
    background-color: #ccc;
    transition: all 0.5s;

    &:hover {
      background-color: #aaa;
    }
  }

  input {
    width: 100%;
    cursor: pointer;
    border: 2px solid #ccc;
    border-left: 0;
    z-index: 2;
  }
`;

export const FilterCheck = styled.div`
  width: 100%;
  position: absolute;
  margin-top: 35px;
  z-index: 2;

  header {
    position: absolute;
    display: flex;
    top: -35px;
    right: 0;
    justify-content: flex-end;
    z-index: 4;

    button {
      display: flex;
      justify-content: center;

      align-items: center;
      border-radius: 4px;

      height: 35px;
      width: 100px;

      z-index: 4;
      font-weight: 700;
      transition: all 0.5s;
      color: #555;
    }

    button:hover {
      background-color: #aaa;
    }
  }

  main {
    width: 100%;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    border: 2px solid #ccc;
    border-top: none;
    padding: 10px 4px;
  }

  footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      width: 110px;
      padding: 10px 5px;
      margin-right: 10px;

      border-radius: 4px;
      font-weight: 700;
      background-color: ${(props) => props.theme.colors.primary};
      transition: all 0.3s;
      color: #fff;
    }

    button:hover {
      opacity: 0.8;
      background-color: ${(props) => props.theme.colors.secondary};
    }

    .clearFilter {
      color: ${(props) => props.theme.colors.secondary};
      background-color: #fff;

      &:hover {
        background-color: ${(props) => props.theme.colors.primaryOpacity};
      }
    }
  }
`;

export const ContainerList = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  .headerTable {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    height: 45px;
    background-color: #ccc;

    h3 {
      font-size: 18px;
      color: #111;
      font-weight: 500;
    }

    button {
      padding: 8px 10px;
      height: 28px;
      background-color: ${(props) => props.theme.colors.secondary};
      border-radius: 5px;

      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: all 0.3s;

      &:hover {
        opacity: 0.6;
      }

      svg {
        color: #fff;
        margin-right: 2px;
      }

      span {
        font-size: 12px;
        color: #fff;
        font-weight: 500;
      }

      @media (max-width: 550px) {
        span {
          display: none;
        }
      }
    }
  }
`;

export const TableContainer = styled.div`
  width: 100%;

  max-width: 100vw;
  overflow-y: hidden;

  ::-webkit-scrollbar {
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #333;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #eee;
  }

  .loadingTableTr {
    cursor: auto;
    &:hover {
      background: transparent;
    }
  }

  .loadingTable {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 20px;
    width: 100%;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 10px;

    thead {
      background-color: #eee;

      tr {
        th {
          padding: 6px 8px;
          font-size: 16px;
          color: #444;
          white-space: nowrap;
        }
      }
    }

    tbody {
      background-color: #fff;
      position: relative;

      tr {
        color: #777;
        border-bottom: 2px solid #eee;
        cursor: pointer;

        &:hover {
          background-color: #eee;
        }

        td {
          position: relative;
          height: 35px;
          text-align: center;
          padding: 0 6px;

          .notification {
            display: flex;
            align-items: center;
            position: absolute;
            left: 8px;
            top: 0px;
            height: 100%;
          }
          .status {
            color: #000;
            font-size: 13px;
            font-weight: 500;
            border-radius: 4px;
            padding: 2px 5px;
            white-space: nowrap;
          }

          .stuationContainer {
            background-color: transparent;
            width: 100%;

            div {
              width: 40px;
              margin: 0 auto;
            }

            svg {
              transition: transform 0.2s ease-in-out;

              &:hover {
                transform: scale(1.3);
              }
            }
          }
        }
      }
    }
  }
`;

export const FooterContainer = styled.div`
  height: 55px;
  width: 100%;
  padding: 10px 10px;
  margin-bottom: 20px;

  background-color: #fff;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 550px) {
    height: 80px;
  }

  span {
    padding-bottom: 10px;
    font-size: 12px;
    font-weight: 400;
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (max-width: 500px) {
      flex-direction: column;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;

      height: 30px;
      padding: 15px 15px;
      margin-right: 10px;

      font-weight: 700;
      background-color: #fff;
      transition: all 0.8s;
      color: #555;
    }

    button:hover {
      background-color: #ccc;
    }

    .buttonNull {
      cursor: not-allowed;
    }
  }
`;

export const Form = styled(FormDevolution)`
  overflow-x: unset;
  margin: 0;

  ::-webkit-scrollbar {
    width: none;
  }

  ::-webkit-scrollbar-thumb {
    width: none;
  }

  ::-webkit-scrollbar-track {
    background: none;
  }

  .field {
    input {
      cursor: auto;
      height: 35px;
    }
  }
`;

export const FilterSelects = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  position: absolute;
  height: 35px;
  width: 100%;
  padding: 0 0 0 48px;

  ul {
    display: fixed;
    align-items: center;
    justify-content: center;

    list-style: none;
    color: #fff;
    z-index: 2;
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 3px;

      border-radius: 4px;
      padding: 2px 5px;
      background-color: #f00;
      margin-right: 5px;

      span {
      }

      svg {
        margin-left: 3px;
        cursor: pointer;
      }
    }
  }
`;

export const Error = styled.div`
  display: flex;
  align-items: center;

  padding: 5px 2px;
  span {
    margin-left: 4px;
    font-weight: 400;
    font-size: 14px;
    color: #f00;
  }
`;

export const ModalBody = styled.div`
  padding: 10px 10px;
  width: 100%;

  h4 {
    text-align: center;
    margin-bottom: 20px;
  }
`;

export const ContainerBilletContent = styled.div`
  .field {
    display: flex;
    flex-direction: column;

    width: 100%;
    margin-bottom: 24px;
    padding: 0 10px;

    label {
      font-size: 14px;
      margin-bottom: 4px;
      color: #555;
    }

    .inputMask {
      position: relative;
      height: 35px;

      .copy {
        display: flex;
        justify-content: center;
        align-items: center;

        position: absolute;
        right: 5px;
        top: 8px;

        background-color: transparent;
        color: #444;

        span {
          margin-right: 4px;
        }

        &:hover {
          color: #000;
        }
      }

      input {
        padding: 0 10px;
        background: #fff;
        height: 100%;
        width: 100%;
        font-size: 16px;
        color: #000;
        border: 2px solid #aaa;
        border-radius: 5px;
      }
    }
  }

  .field-group {
    width: 100%;
    display: flex;
    flex-flow: row;
  }

  @media (max-width: 900px) {
    .field-group {
      flex-flow: column;
    }

    .field {
      padding: 0 2px;
      input {
        padding: 0 0;
      }
    }
  }
`;

export const ContainerVideo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 6px;
  width: 100%;

  .video {
    flex: 1;
    width: 100%;
    height: 200px;
  }

  .img {
    cursor: pointer;
    margin-right: 50px;
    width: 20%;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    margin-bottom: 8px;

    .img {
      margin-right: 0px;
      margin-bottom: 8px;
    }
  }
`;

export const ContainerAlertBillet = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  height: 100%;
  width: 100%;

  .headerAlert {
    height: 20%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    margin-top: 40px;
    margin-bottom: 40px;

    .containerIcon {
      font-size: 50px;
    }

    span {
      font-size: 25px;
      font-weight: 700;
    }
  }

  .bodyAlert {
    text-align: center;
    padding: 0 20px;

    .contatos {
      margin-top: 20px;
      span {
        font-weight: 500;
      }
    }
  }
`;

export const SlaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 100px;
  flex: 1;
  height: 100%;

  .boxSla {
    position: relative;
    overflow: hidden;

    height: 65%;
    max-height: 23px;
    width: 90%;

    background-color: #fbfbfb;
    border-radius: 8px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);

    .percentageSla {
      position: absolute;
      margin: auto;

      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      span {
        font-size: 12px;
        font-weight: 700;
        color: #444;
      }
    }

    .valueSla {
      height: 100%;
      width: 25%;

      /* background-color: rgba(255, 220, 0, 0.6); */
      /* background-color: rgba(255, 61, 61, 0.8); */
      background-color: rgba(90, 182, 95, 0.8);
    }
  }
`;

export const ReportDownload = styled.div`
  position: relative;
  cursor: pointer;

  .icon-execel {
    display: flex;
    justify-content: center;
    align-items: center;

    .row-excel {
      transition: 0.4s;
      transform: rotate(0deg);
    }

    .row-excel-active {
      transition: 0.4s;
      transform: rotate(180deg);
    }
  }

  .optionReport {
    animation: fadeout 0.4s;

    @keyframes fadeout {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
    box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.59);

    position: absolute;
    top: 30px;
    right: -10px;
    width: 180px;
    border-radius: 5px;

    background-color: #fff;
    overflow: hidden;
    list-style: none;
    z-index: 5;

    li {
      display: flex;
      align-items: center;
      transition: all 0.3s;

      padding: 8px 15px;
      width: 100%;

      border-bottom: 1px solid #bbb;

      &:last-child {
        border-bottom: none;
      }

      .icon-execel {
        margin-right: 8px;
      }

      span {
        font-size: 14px;
      }
    }

    li:hover {
      background-color: #ccc;
    }
  }
`;

export const ExternalLink = styled.td`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  a {
    background-color: transparent;
    transition: 0.15s all;
    color: #333;

    &:hover {
      transform: scale(1.2);
    }
  }
`;

export const ExternalLinkA = styled.a`
  background-color: transparent;
  transition: 0.15s all;
  color: #333;

  &:hover {
    transform: scale(1.2);
  }
`;
