import React, { useRef, useState } from "react";

import { Link } from "react-router-dom";

import { InfoUser, OptionNav } from "./styles";

import * as UserStorage from "../../service/localStorage/user";

import { TiUser } from "react-icons/ti";
import { MdExitToApp } from "react-icons/md";
// import { FaUser, FaLock } from "react-icons/fa";
import { BsFillCaretDownFill } from "react-icons/bs";
import useOutsideAlerter from "../../hook/outsideAlerter";
import { IUser } from "../../@types/types";

const NavUserInfo: React.FC = () => {
  const outSideRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const UserData = UserStorage.getUser() as IUser;

  useOutsideAlerter(outSideRef, () => {
    if (visible) setVisible(false);
  });

  function handleClick() {
    setVisible(!visible);
  }

  function hadleLogout() {
    localStorage.clear();

    return window.location.reload();
  }

  return (
    <>
      <InfoUser onClick={handleClick} ref={outSideRef}>
        <div className="info-user">
          <h3>{`${UserData.firstname} ${UserData.lastname}`}</h3>
          <span>{UserData.email}</span>
        </div>
        <div className="icon-user">
          <TiUser color="#fff" size={40} onChange={(e) => console.log(e)} />
        </div>
        <BsFillCaretDownFill
          className={visible ? "triangulo-active" : "triangulo"}
          color="#333"
          size={23}
        />
      </InfoUser>
      {visible && (
        <OptionNav>
          {/* <Link to={"/perfil"} onClick={handleClick}>
            <li>
              <FaUser size={20} />
              Meu Perfil
            </li>
          </Link>

          <Link to={"/seguranca"} onClick={handleClick}>
            <li>
              <FaLock size={20} />
              Segurança
            </li>
          </Link> */}

          <Link to={"/"} onClick={hadleLogout}>
            <li>
              <MdExitToApp size={20} />
              Sair
            </li>
          </Link>
        </OptionNav>
      )}
    </>
  );
};

export default NavUserInfo;
