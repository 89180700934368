import React from "react";

import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";

import { Formik } from "formik";
import { FiAlertCircle } from "react-icons/fi";
import { FaArrowLeft } from "react-icons/fa";
import * as Yup from "yup";

import api from "../../../service/api";
import { Container, ContainerForm, Form, ButtunSubmit, Error } from "../styles";

const CreateUser: React.FC = () => {
  const { addToast } = useToasts();
  const history = useHistory();

  async function handleSubmit(create: any) {
    try {
      const departamentalEvaluation = await api.post(
        "/departamental_evaluation",
        {
          title: create.title,
          qtd: create.qtd,
        }
      );

      addToast("Avaliação criado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push(`/avaliacao/${departamentalEvaluation.data.id}`);
    } catch (error) {
      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <Container>
      <header>
        <button
          type="button"
          onClick={() => {
            history.goBack();
          }}
          className="arrowBack"
        >
          <FaArrowLeft size={18} />
        </button>

        <h2>Criar Avaliação departamental</h2>
      </header>

      <ContainerForm>
        <Formik
          initialValues={{
            title: undefined,
            qtd: undefined,
          }}
          validationSchema={Yup.object({
            title: Yup.string().required("Titulo é obrigatório"),
            qtd: Yup.number()
              .integer("QTD Clientes e necessario ser numero inteiro")
              .required("QTD Clientes é obrigatório"),
          })}
          onSubmit={(data) => handleSubmit(data)}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <div className="field-group">
                <div className="field">
                  <label htmlFor="title">Título</label>
                  <input {...formik.getFieldProps("title")} />
                  {formik.touched.title && formik.errors.title ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.title} </span>
                    </Error>
                  ) : null}
                </div>
                <div className="field">
                  <label htmlFor="qtd">QTD Clientes</label>
                  <input type="number" {...formik.getFieldProps("qtd")} />
                  {formik.touched.qtd && formik.errors.qtd ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.qtd} </span>
                    </Error>
                  ) : null}
                </div>
              </div>

              <ButtunSubmit type="submit"> Criar </ButtunSubmit>
            </Form>
          )}
        </Formik>
      </ContainerForm>
    </Container>
  );
};

export default CreateUser;
