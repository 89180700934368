import styled from "styled-components";

import avaliacaoDepartamental from "../../assets/all_the_data.svg";

export const Container = styled.div`
  height: 100vh;

  background: url(${avaliacaoDepartamental}) no-repeat 650px bottom;
  background-size: 700px;

  background-color: ${(props) => props.theme.colors.background};

  @media (min-width: 1400px) {
    background: url(${avaliacaoDepartamental}) no-repeat 860px bottom;
    background-size: 900px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  position: relative;

  #logo-alpar {
    width: 22vw;
    min-width: 200px;
    margin-bottom: 30px;
  }

  a {
    color: #d0222d;
  }

  .headerViewPassword {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    color: #333;
    margin-bottom: 15px;

    p {
      display: flex;
      justify-items: center;
      align-items: center;

      svg {
        margin-right: 5px;
        margin-bottom: 2px;
      }
    }
  }

  .containerButtons {
    width: 100%;
    display: grid;

    grid-auto-columns: 1fr 1fr;
    grid-auto-rows: auto;
    column-gap: 20px;
    grid-template-areas: "button button";
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 30px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }

  header {
    height: 15%;
    width: 300px;

    padding: 15px 0 0 10px;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    svg {
      max-height: 100%;
    }
  }

  main {
    /* flex: 1; */
    max-width: 560px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    form {
      max-width: 560px;
      width: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    h1 {
      font-size: 54px;
      color: ${(props) => props.theme.colors.primary};
      margin-top: 30px;
    }
    p {
      font-size: 24px;
      margin-top: 24px;
      line-height: 38px;
      max-width: 400px;
      color: ${(props) => props.theme.colors.tertiary};
    }

    .field {
      margin-top: 40px;
      width: 100%;
      max-width: 360px;
      height: 30px;

      position: relative;
      height: 48px;
      margin-bottom: 1.5rem;

      position: relative;

      label {
        position: absolute;
        left: 1rem;
        top: 0.9rem;
        padding: 0 0.25rem;
        background-color: ${(props) => props.theme.colors.background};
        color: #555;
        font-weight: 700;
        font-size: ${(props) => props.theme.sizes.normalFontSize};
        transition: 0.2s;
      }

      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: ${(props) => props.theme.sizes.normalFontSize};
        border: 2px solid #555;
        border-radius: 0.5rem;
        outline: none;
        padding: 1rem;
        background: none;
        z-index: 1;
        color: #555;
      }

      input:focus + label {
        top: -0.4rem;
        left: 0.8rem;
        color: #555;
        font-size: ${(props) => props.theme.sizes.smallFontSize};
        font-weight: 500;
        z-index: 10;
      }

      .active-label {
        top: -0.4rem;
        left: 0.8rem;
        color: #555;
        font-size: ${(props) => props.theme.sizes.smallFontSize};
        font-weight: 500;
        z-index: 10;

        input:focus {
          border: 2px solid #fff;
        }
      }

      .active-label-error {
        top: -0.4rem;
        left: 0.8rem;
        color: #555;
        font-size: ${(props) => props.theme.sizes.normalFontSize};
        font-weight: 500;
        z-index: 10;

        input:focus {
          border: 2px solid #d93025;
        }
      }

      .error-icon {
        position: absolute;

        top: 0.8rem;
        right: 0.8rem;

        svg {
          color: #d93025;
          width: 22px;
          height: 22px;
        }
      }

      .error-input {
        border: 2px solid #d93025;
      }

      input:not(:placeholder-shown) input:not(:focus) + input {
        top: -0.5rem;
        left: 0.8rem;
        font-size: ${(props) => props.theme.sizes.normalFontSize};
        font-weight: 500;
        z-index: 10;
      }
    }

    .field:nth-child(2) {
      margin-top: 0px;
    }

    .buttonSubmit {
      width: 100%;
      max-width: 360px;
      height: 72px;
      background: ${(props) => props.theme.colors.primary};
      border-radius: 8px;
      text-decoration: none;
      margin-bottom: 40px;
      border: none;
      cursor: pointer;

      display: flex;
      align-items: center;
      overflow: hidden;
      margin-top: 10px;

      span {
        display: block;
        background: rgba(0, 0, 0, 0.08);
        width: 72px;
        height: 72px;

        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.2s;
        svg {
          color: #fff;
          width: 20px;
          height: 20px;
        }
      }

      strong {
        flex: 1;
        text-align: center;
        color: #fff;
        font-size: ${(props) => props.theme.sizes.normalFontSize};

        display: flex;
        justify-content: center;
        align-items: center;
      }

      transition: all 0.4s;
    }

    button:hover {
      background: ${(props) => props.theme.colors.secondary};
    }
    button:focus {
      outline-style: none;
    }
  }

  @media (max-width: 900px) {
    align-items: center;
    text-align: center;

    header {
      margin: 48px auto 0;

      justify-content: center;
    }
    main {
      align-items: center;

      form {
        justify-content: center;
        align-items: center;
      }
    }

    h1 {
      font-size: 42px;
    }
    p {
      font-size: 24px;
    }
  }
`;

export const Form = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

  width: 90vw;
  max-width: 420px;
  padding: 20px 15px 30px 15px;
  border-radius: 4px;
  background: #fff;

  form {
    width: 95%;
    height: 100%;
  }

  a {
    font-weight: 400;
    font-size: 14px;
  }
`;

export const ContainerInput = styled.div`
  height: 40px;
  width: 100%;
  margin-bottom: 28px;
  position: relative;

  svg {
    position: absolute;
    top: 8px;
    left: 5px;
    color: #f00;
  }

  input {
    height: 100%;
    width: 100%;

    padding: 1px 0px 0px 35px;
    font-size: 15px;

    color: #888;
    border: none;
    border-bottom: 1.3px solid #999;
  }

  input:focus-within {
    color: #333;
    border-bottom: 1.3px solid #333;
  }
`;

export const Error = styled.div`
  height: 30px;
  width: 95%;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 5px;
  margin: 0 0 5px 0;

  svg {
    margin-right: 5px;
  }

  span {
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }
`;

export const ButtunSubmit = styled.button`
  width: 100%;
  height: 40px;
  margin-top: 10px;
  margin-bottom: 10px;

  font-weight: 700;
  font-size: 16px;
  color: #fff;

  background: #d21e26;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 0.85;
  }
`;

export const ContainerError = styled.div`
  display: flex;
  justify-content: center;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    color: ${(props) => props.theme.colors.error};
  }

  span {
    font-weight: 700;
    text-align: center;
    color: ${(props) => props.theme.colors.error};

    margin: auto 0;
  }
`;
