import React, { useState, useRef, useEffect } from "react";

import { useHistory } from "react-router-dom";
import api from "../../../service/api";

import {
  Container,
  ContainerList,
  TableContainer,
  FooterContainer,
} from "../styles";

import theme from "../../../styles/theme";

import { MdAdd } from "react-icons/md";
import Loading from "../../../components/Loading";
import useOutsideAlerter from "../../../hook/outsideAlerter";

import {
  IPagination,
  IRequestPagination,
  IResponseApiPagination,
  IResponseItem,
} from "../../../@types/types";

interface IResponseAPI extends IResponseApiPagination {
  contents: IResponseItem[];
}

// const APP_LINK = "http://10.1.1.154:3000";

const Panel: React.FC = () => {
  const history = useHistory();
  const outSideRef = useRef(null);

  const [filterView, setFilterView] = useState(false);

  const [loading, setLoading] = useState({
    table: true,
    page: true,
  });
  const [contents, setContents] = useState<IResponseItem[]>([]);
  const [pagination, setPagination] = useState<IPagination>({} as IPagination);
  const [
    requestPagination,
    setRequestPagination,
  ] = useState<IRequestPagination>({ limitRequest: 15 } as IRequestPagination);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get<IResponseAPI>("/response_item", {
          params: { ...requestPagination },
        });

        const { contents, limit, page, pages, total } = response.data;

        setContents(contents);
        setPagination({ limit, page, pages, total });
        setLoading({ ...loading, table: false });
      } catch (error) {}
    })();
    // eslint-disable-next-line
  }, [requestPagination]);

  function hadleNextPage() {
    setRequestPagination({
      ...requestPagination,
      pageRequest: pagination.page + 1,
    });
  }

  function hadlePreviousPage() {
    setRequestPagination({
      ...requestPagination,
      pageRequest: pagination.page - 1,
    });
  }

  useOutsideAlerter(outSideRef, () => {
    if (filterView) setFilterView(false);
  });

  return (
    <Container>
      <header>
        <h2>Lista de Respostas</h2>
      </header>

      <ContainerList>
        <div className="headerTable">
          <h3>Respostas</h3>

          <button onClick={() => history.push("/criar/resposta")} type="button">
            <MdAdd size={22} /> <span> CRIAR </span>
          </button>
        </div>

        <TableContainer>
          <table>
            <thead>
              <tr>
                <th>CÓDIGO</th>
                <th>DESCRIÇÃO</th>
                <th>VALIDO</th>
              </tr>
            </thead>

            <tbody>
              {!loading.table ? (
                contents.map((item) => (
                  <tr
                    key={item.id}
                    onClick={() => {
                      history.push(`/resposta/${item.id}`);
                    }}
                  >
                    <td>{item.id}</td>
                    <td>{item.description}</td>
                    <td>{item.valid ? "Sim" : "Não"}</td>
                  </tr>
                ))
              ) : (
                <>
                  <tr className="loadingTableTr">
                    <td></td>
                    <td>
                      <div className="loadingTable">
                        <Loading
                          size={35}
                          borderSize={4}
                          colorLoading={theme.colors.primary}
                          borderColor="rgba(0,0,0, 0.3)"
                        />
                      </div>
                    </td>
                    <td></td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </TableContainer>
        <FooterContainer>
          <span>
            Mostrado{" "}
            {contents.length + pagination.limit * pagination.page
              ? contents.length + pagination.limit * pagination.page
              : 0}{" "}
            de {pagination.total ? pagination.total : 0} resultados
          </span>

          <div>
            <button
              className={pagination.page < 1 ? "buttonNull" : ""}
              onClick={pagination.page < 1 ? () => {} : hadlePreviousPage}
              type="button"
            >
              ANTERIOR
            </button>
            <p style={{ marginRight: 8 }}>
              {pagination.page + 1 ? pagination.page + 1 : 0}
            </p>
            <button
              className={
                pagination.page >= pagination.pages ? "buttonNull" : ""
              }
              type="button"
              onClick={
                Number(pagination.page) >= Number(pagination.pages)
                  ? () => {}
                  : hadleNextPage
              }
            >
              PROXIMO
            </button>
          </div>
        </FooterContainer>
      </ContainerList>
    </Container>
  );
};

export default Panel;
